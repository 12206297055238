import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetInTouch, fetchRanges } from 'redux/actions';
import { useWindowSize } from 'hooks/use-window-size';
import { getPage } from 'modules/api';
import urls from 'constants/urls.json';
import {
  BANNER_NAME_VERTICAL_COPY,
  BANNER_NAME_GET_IN_TOUCH_HOME,
} from 'constants/banners';
import { THEME_DARK } from 'constants/color-theme';
import { PAGE_HOME } from 'constants/page-name';
import { TITLE_BEAUTIFULLY_LIT } from 'constants/titles';
import { ROUTE_GET_IN_TOUCH } from 'constants/router-links';
import { bpSmallPlus } from 'constants/breakpoints';
import { Loading } from 'components/loading';
import { Header } from 'components/header';
import { SimpleHero } from 'components/simple-hero';
import { RealiseAndEnhance } from 'components/realise-and-enhance';
import { SectionWithBackground } from 'components/section-with-background';
import { ProjectFeatured } from 'components/project-featured';
import { NewsFeatured } from 'components/news-featured';
import { SignUpSection } from 'components/signup-section';
import { SlideUp } from 'components/animations';
import { ErrorBoundary } from 'components/error';
import { Footer } from 'components/footer';
import { RangeGrid } from 'components/range-grid';
import { Spacer } from 'components/spacer';

export const Home = () => {
  const dispatch = useDispatch();
  const { ranges, rangesLoading } = useSelector((state) => state.ranges);
  const { banner } = useSelector((state) => state.getInTouchBanner);
  const [loading, isLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const { width } = useWindowSize();
  const smallScreen = width < bpSmallPlus;

  useEffect(() => {
    dispatch(fetchRanges());
    async function fetchPage() {
      const page = await getPage(urls['page'], 'home');
      setPageData(page);
      isLoading(false);
    }

    fetchPage();
  }, [loading, dispatch]);

  useEffect(() => {
    dispatch(fetchGetInTouch());
  }, [dispatch]);

  if (loading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_DARK} gradient />
      <SimpleHero
        pageName={PAGE_HOME}
        theme={THEME_DARK}
        desktop={pageData.acf.hero_image_desktop}
        mobile={pageData.acf.hero_image_mobile}
        videoDesktop={pageData.acf.hero_video_desktop}
        videoMobile={pageData.acf.hero_video_mobile}
        hideTitle={pageData.acf.hide_headline}
        title={TITLE_BEAUTIFULLY_LIT}
        smallScreen={smallScreen}
        addCta={pageData.acf.add_cta}
        heroCta={pageData.acf.hero_cta}
      />
      <RealiseAndEnhance
        videoDesktop={pageData.acf.featured_video_desktop}
        videoMobile={pageData.acf.featured_video_mobile}
        videoImageDesktop={pageData.acf.featured_video_image_desktop}
        videoImageMobile={pageData.acf.featured_video_image_mobile}
        videoCaption={pageData.acf.featured_video_caption}
        enhanceImage={pageData.acf.featured_image}
        productCollection={pageData.acf.product_collection}
        circularImageDesktop={pageData.acf.circular_image_desktop}
        circularImageMobile={pageData.acf.circular_image_mobile}
        circularProduct={pageData.acf.circular_product}
        bestLitEffect={pageData.acf.best_lit_effect}
        smallScreen={smallScreen}
      />
      <SlideUp>
        <SectionWithBackground
          theme={THEME_DARK}
          sectionName={BANNER_NAME_VERTICAL_COPY}
          description={pageData.acf.project_banner.banner_caption}
          hasFeaturedProduct={pageData.acf.show_featured_product}
          image={
            smallScreen
              ? pageData.acf.project_banner.mobile.image
              : pageData.acf.project_banner.desktop.image
          }
          video={
            smallScreen
              ? pageData.acf.project_banner.mobile.video
              : pageData.acf.project_banner.desktop.video
          }
          relatedProduct={pageData.acf.featured_product}
          nextCompIsVisible={pageData.acf.toggle_featured_project}
        />
      </SlideUp>
      {/* if the below component changes, please update nextCompIsVisible props above */}
      {pageData.acf.toggle_featured_project && (
        <SlideUp>
          <ProjectFeatured project={pageData.acf.featured_project} />
        </SlideUp>
      )}
      {pageData.acf.display_range_grid && (
        <RangeGrid
          content={pageData.acf.range_grid}
          loading={rangesLoading}
          ranges={ranges}
        />
      )}
      {pageData.acf.toggle_featured_news ? (
        <SlideUp>
          <NewsFeatured news={pageData.acf.featured_news} />
        </SlideUp>
      ) : (
        <Spacer />
      )}
      {
        /* banner.display_banner */ false && (
          <SlideUp>
            <SectionWithBackground
              theme={THEME_DARK}
              sectionName={BANNER_NAME_GET_IN_TOUCH_HOME}
              description={banner.content.title}
              subDescription={banner.content.description}
              cta={banner.content.button_title}
              link={ROUTE_GET_IN_TOUCH}
              image={
                smallScreen
                  ? banner.content.background_mobile
                  : banner.content.background_desktop
              }
            />
          </SlideUp>
        )
      }
      <SignUpSection />
      <Footer />
    </ErrorBoundary>
  );
};
