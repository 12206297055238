import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitContactUs } from 'redux/actions';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Button } from 'components/buttons';
import { CheckboxDefault } from 'components/check-box/checkbox-default';
import { SelectedCheckbox } from 'components/icons';
import { Loading } from 'components/loading';
import { REGEX_EMAIL, REGEX_NAME, REGEX_SURNAME } from 'constants/form-regex';
import { ROUTE_COOKIES } from 'constants/router-links';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_SUBMIT } from 'constants/labels';
import { SIZE_DEFAULT } from 'constants/sizes';

import styles from './style.module.scss';

export const ContactForm = () => {
  const { register, handleSubmit, watch, errors } = useForm();
  const watchFields = watch(['name', 'surname', 'email', 'message']);
  const dispatch = useDispatch();
  const [privacyIsChecked, setPrivacyIsChecked] = useState(false);
  const [newsIsChecked, setNewsIsChecked] = useState(false);
  const [enquiryValue, setEnquiryValue] = useState(null);
  const [regionValue, setRegionValue] = useState(null);
  const [selectValues, setSelectValues] = useState({});
  const { loading } = useSelector((state) => state.contactForm);

  const onSubmit = (data, e) => {
    dispatch(
      submitContactUs({
        ...data,
        context: 'website',
        company: '',
        phone: '',
        ...selectValues,
      })
    );
    //reset all fields
    e.target.reset();
    setPrivacyIsChecked(false);
    setNewsIsChecked(false);
    setEnquiryValue(null);
    setRegionValue(null);
  };

  const handleEnquiryChange = (selectedOption) => {
    setEnquiryValue(selectedOption);
    setSelectValues({
      ...selectValues,
      type: selectedOption.value,
    });
  };

  const handleRegionChange = (selectedOption) => {
    setRegionValue(selectedOption);
    setSelectValues({
      ...selectValues,
      region: selectedOption.value,
    });
  };

  /* required */
  const handlePrivacyCheckbox = () => {
    setPrivacyIsChecked(!privacyIsChecked);
  };
  /* not required */
  const handleNewsCheckbox = () => {
    setNewsIsChecked(!newsIsChecked);
  };

  const enquiryTypes = [
    //{ value: 'trial', label: 'Trial light sample' },
    { value: 'general', label: 'General enquiries' },
    { value: 'quotation', label: 'Quotation' },
  ];

  const regionOptions = [
    { value: 'uk', label: 'UK' },
    { value: 'europe', label: 'Europe' },
    { value: 'usa', label: 'USA' },
    { value: 'mid-east', label: 'Middle East' },
    { value: 'APAC', label: 'Asia Pacific' },
  ];

  const nameIsValid = watchFields.name !== undefined && watchFields.name !== '';
  const surnameIsValid =
    watchFields.surname !== undefined && watchFields.surname !== '';
  const emailIsValid =
    watchFields.email !== '' && watchFields.email !== undefined;
  const textareaIsValid =
    watchFields.message !== undefined && watchFields.message.length > 0;
  const selectIsValid = enquiryValue !== null && regionValue !== null;

  const emailClass = classNames({
    [`${styles.email}`]: true,
    [`${'has-error'}`]: errors.email,
  });

  const textareaClass = classNames({
    [`${styles.textarea}`]: true,
    [`${'has-error'}`]: errors.message,
  });

  return (
    <div className={styles['contact-form']}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={styles['top-group']}>
          <div className={styles['left-side']}>
            <div className={styles.name}>
              <input
                ref={register({ pattern: REGEX_NAME })}
                className={errors.name ? 'has-error' : ''}
                type="text"
                name="name"
                placeholder="First name*"
              />
              {errors.name && (
                <small className="error">please enter a valid first name</small>
              )}
            </div>
            <div className={styles.surname}>
              <input
                ref={register({ pattern: REGEX_SURNAME })}
                className={errors.surname ? 'has-error' : ''}
                type="text"
                name="surname"
                placeholder="Surname*"
              />
              {errors.surname && (
                <small className="error">please enter a valid surname</small>
              )}
            </div>
          </div>

          <div className={styles['right-side']}>
            <div className={styles['select-item-container']}>
              <label className={styles.label} htmlFor={'Enquiry type'}>
                Enquiry type<sup>*</sup>
              </label>
              <Select
                ref={register}
                value={enquiryValue}
                options={enquiryTypes}
                placeholder="select..."
                id={'enquiry'}
                name={'enquiry'}
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={handleEnquiryChange}
              />
            </div>

            <div className={styles['select-item-container']}>
              <label className={styles.label} htmlFor={'Region'}>
                Region<sup>*</sup>
              </label>
              <Select
                ref={register}
                value={regionValue}
                options={regionOptions}
                placeholder="select..."
                id={'region'}
                name={'region'}
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={handleRegionChange}
              />
            </div>
          </div>

          <div className={styles['email-desktop']}>
            <input
              ref={register({ required: true, pattern: REGEX_EMAIL })}
              className={emailClass}
              type="email"
              name="email"
              placeholder="Email address*"
            />
            {errors.email && (
              <small className="error">please enter a valid e-mail</small>
            )}
          </div>
        </div>

        <div className={styles['textarea-wrapper']}>
          <label>
            Message<sup>*</sup>
          </label>
          <textarea
            ref={register({ required: true })}
            className={textareaClass}
            type="text"
            name="message"
            placeholder="type something here..."
          />
          {errors.message && <small className="error">{errors.message}</small>}
        </div>
        <div className={styles['privacy-wrapper']}>
          <div className={styles.privacy}>
            <label htmlFor={'privacy'}>
              <CheckboxDefault
                register={register}
                icon={<SelectedCheckbox />}
                id="privacy"
                name="privacy"
                checked={privacyIsChecked}
                value={privacyIsChecked}
                onChange={handlePrivacyCheckbox}
              />
            </label>
            <small>
              I have read and accepted Orluna's{' '}
              <a
                className={styles.terms}
                href={ROUTE_COOKIES}
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>
              *
            </small>
          </div>
          <div className={styles.newsletter}>
            <label htmlFor={'newsletter'}>
              <CheckboxDefault
                register={register}
                icon={<SelectedCheckbox />}
                id="newsletter"
                name="newsletter"
                checked={newsIsChecked}
                value={newsIsChecked}
                onChange={handleNewsCheckbox}
              />
            </label>
            <small>
              I would like to subscribe to the Orluna mailing list to recieve
              product news, information and offers.
            </small>
          </div>
        </div>
        <Button
          size={SIZE_DEFAULT}
          theme={THEME_DARK}
          label={LABEL_SUBMIT}
          disabled={
            textareaIsValid &&
            nameIsValid &&
            surnameIsValid &&
            emailIsValid &&
            selectIsValid &&
            !loading &&
            privacyIsChecked
              ? false
              : true
          }
        />
        <small className={styles.mandatory}>
          <sup>*</sup>mandatory fields
        </small>
        {loading && <Loading overlay fit />}
      </form>
    </div>
  );
};
