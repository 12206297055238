import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import urls from 'constants/urls.json';
import { getPage } from 'modules/api';
import { useWindowSize } from 'hooks/use-window-size';
import { fetchGetInTouch } from 'redux/actions';
import { bpSmallPlus } from 'constants/breakpoints';
import { SIZE_BIG, SIZE_SMALL } from 'constants/sizes';
import { PAGE_OUR_STORY, PAGE_PROJECT_INFO } from 'constants/page-name';
import { BANNER_NAME_GET_IN_TOUCH_HOME } from 'constants/banners';
import { ROUTE_GET_IN_TOUCH } from 'constants/router-links';
import { THEME_DARK } from 'constants/color-theme';
import { TITLE_OUR_STORY } from 'constants/titles';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { SimpleHero } from 'components/simple-hero';
import { PageStatement } from 'components/page-statement';
import { StoryDescription } from 'components/story-description';
import { DesignAndBeauty } from 'components/design-and-beauty';
import { SignUpSection } from 'components/signup-section';
import { Loading } from 'components/loading';
import { ErrorBoundary } from 'components/error';
import { SectionWithBackground } from 'components/section-with-background';

export const OurStory = () => {
  const { width } = useWindowSize();
  const smallScreen = width < bpSmallPlus;
  const dispatch = useDispatch();
  const { banner } = useSelector((state) => state.getInTouchBanner);
  const [loading, isLoading] = useState(true);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    async function fetchPage() {
      const page = await getPage(urls['page'], 'our-story');
      setPageData(page);
      isLoading(false);
    }

    fetchPage();
  }, [loading]);

  useEffect(() => {
    dispatch(fetchGetInTouch());
  }, [dispatch]);

  if (loading) return <Loading />;

  return (
    <ErrorBoundary>
      <Header theme={THEME_DARK} />
      <SimpleHero
        pageName={PAGE_OUR_STORY}
        theme={THEME_DARK}
        desktop={pageData.acf.hero_desktop}
        mobile={pageData.acf.hero_mobile}
        video={pageData.acf.hero_video}
        hideTitle={pageData.acf.hide_headline}
        title={TITLE_OUR_STORY}
      />
      <PageStatement
        pageName={PAGE_OUR_STORY}
        size={SIZE_SMALL}
        text={pageData.acf.intro}
      />
      <StoryDescription
        imageTop={pageData.acf.featured_image}
        imageRight={pageData.acf.featured_image_2}
        featuredText={pageData.acf.page_copy_1}
        singleImage={pageData.acf.single_image}
        singleImageText={pageData.acf.page_copy_2}
      />
      <PageStatement
        pageName={PAGE_PROJECT_INFO}
        size={SIZE_BIG}
        text={pageData.acf.quote}
      />
      <DesignAndBeauty
        imageOne={pageData.acf.cta_image_1}
        copyOne={pageData.acf.cta_copy_1}
        buttonOne={pageData.acf.cta_button_1}
        imageTwo={pageData.acf.cta_image_2}
        copyTwo={pageData.acf.cta_copy_2}
        buttonTwo={pageData.acf.cta_button_2}
      />
      {
        /* banner.display_banner */ false && (
          <SectionWithBackground
            theme={THEME_DARK}
            sectionName={BANNER_NAME_GET_IN_TOUCH_HOME}
            description={banner.content.title}
            subDescription={banner.content.description}
            cta={banner.content.button_title}
            link={ROUTE_GET_IN_TOUCH}
            image={
              smallScreen
                ? banner.content.background_mobile
                : banner.content.background_desktop
            }
          />
        )
      }
      <SignUpSection />
      <Footer />
    </ErrorBoundary>
  );
};
